import React from 'react';

export const AxisLeft = ({yScale, innerWidth}) =>
  yScale.ticks().map((tickValue) => (
    <g key={tickValue}>
      <line x2={innerWidth} y1={yScale(tickValue)} y2={yScale(tickValue)} stroke="yellowgreen" />
      <text
        key={tickValue}
        style={{textAnchor: `end`}}
        x={-10}
        y={yScale(tickValue)}
        dy=".32em"
        fill='brown'
      >
        {tickValue}
      </text>
    </g>
  ));
