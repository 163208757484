import React from 'react';
import PropTypes from 'prop-types';
import FilmsList from '../films-list/films-list';
import {Film} from '../../prop-types/film';
import {Link} from "react-router-dom";


const MyListPage = (props) => {
  const {films, onSort} = props;
  return (
    <div className="user-page">
      <header className="page-header user-page__head">
        <div className="logo">
          <Link to="/" className="logo__link">
            <span className="logo__letter logo__letter--1">W</span>
            <span className="logo__letter logo__letter--2">T</span>
            <span className="logo__letter logo__letter--3">D</span>
          </Link>
        </div>

        <h1 className="page-title user-page__title">My list</h1>

        <div className="user-block">
          <div className="user-block__avatar">
            <img src="img/ban006.jpg" alt="User avatar" width="63" height="63" />
          </div>
        </div>
      </header>

      <section className="catalog">
        <h2 className="catalog__title visually-hidden">Catalog</h2>

        <div className="catalog__movies-list">
          <FilmsList films={films} onSort={onSort} />

        </div>
      </section>

      <footer className="page-footer">
        <div className="logo">
          <Link to="/" className="logo__link logo__link--light">
            <span className="logo__letter logo__letter--1">W</span>
            <span className="logo__letter logo__letter--2">T</span>
            <span className="logo__letter logo__letter--3">D</span>
          </Link>
        </div>

        <div className="copyright">
          <p>© 2024 What to drink Ltd.</p>
        </div>
      </footer>
    </div>
  );
};

MyListPage.propTypes = {
  films: PropTypes.arrayOf(Film).isRequired,
  // films: PropTypes.array.isRequired,
};

export default MyListPage;
