import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/app/app';
// import films from './mocks/films';
import teaIHave from './mocks/tea_i_have';

ReactDOM.render(
    <App
      films={teaIHave}
    />,
    document.querySelector(`#root`)
);
