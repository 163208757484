import React, {useState} from 'react';
import PropTypes from 'prop-types';
// import FilmCard from '../card/card';
import FilmsList from '../films-list/films-list';
// import films from '../../mocks/films';
import {Film} from '../../prop-types/film';
import {Link} from "react-router-dom";
// import {scaleBand, scaleLinear, max} from 'd3';
import {BarChart} from '../charts/BarChart/BarChart';
import {ScatterPlot} from '../charts/ScatterPlot/ScatterPlot';
import {ScatterPlotByYear} from '../charts/ScatterPlotByYear/ScatterPlotByYear';
import {Helmet} from 'react-helmet';
// import { NavLink } from 'react-router-dom/cjs/react-router-dom';


const MainPage = (props) => {
  const {films, onSort, onSortByPrice, onSortByYear, filterHaivan, filterAllTea, filterDaI, filterLimin, filterWooden, filterResin, filterTulin, filterJunCha, filterUnGen, filterShu, filterBlack, filterFruit, filterNuts, filterBerries, filterMusk, filterChoco, filterHvoya, filterCamphor} = props;
  const costAllMyTea = Math.floor(films.reduce(function (sum, item) {
    return sum + (item.cost * item.amount / item.weight);
  }, 0));

  // let meta = document.createElement(`meta`);
  // meta.name = `description`;
  // meta.content = `Heishu (Хэйшу). Интернет-магазин китайского чая - шу пуэра и хэй ча. Доставляем по России и за рубеж, в текущий момент находимся в городе Павловский Посад Московcкой области.`;
  // document.head.appendChild(meta);


  return (
    <>

      <Helmet>
        <title>Heishu (Хэйшу) - интернет-магазин шу-пуэра и хэйчи</title>
        <meta name="description" content="Heishu (Хэйшу). Интернет-магазин китайского чая - шу пуэра и хэй ча. Доставляем по России и за рубеж, в текущий момент находимся в городе Павловский Посад Московcкой области." />
      </Helmet>

      <section className="movie-card">

        <div className="movie-card__bg">
          {/* <Link to="/teabucket">🫖</Link> */}
          <img src="img/itsteatime.webp" alt="Heishu - the tea magazine" />
          <div className="main-menu">
            <h2 style={{color: `indianred`, fontSize: 50}}>HEISHU ХЭЙШУ</h2>
            <p style={{color: `#eee5b5`, fontSize: 25, padding: 30}}>Интернет-магазин китайского чая - шу пуэра и хэй ча. Доставляем по России и за рубеж, в текущий момент находимся в городе Павловский Посад Московcкой области.</p>
            <Link to="/teabucket" style={{color: `indigo`, fontSize: 25, lineHeight: `30px`}}>🫖Корзина с товарами</Link><br/>
            <Link to="/graphics" style={{color: `green`, fontSize: 25, lineHeight: `30px`}}>📊Графики</Link><br/>
            <Link to="/#page-content" onClick={() => filterShu()} style={{color: `green`, fontSize: 25, lineHeight: `30px`}}>茶 Шу-пуэр</Link><br/>
            <Link to="/#page-content" onClick={() => filterBlack()} style={{color: `green`, fontSize: 25, lineHeight: `30px`}}>茶 Хэйча</Link><br/>
            <Link to="/mylist" style={{color: `green`, fontSize: 25, lineHeight: `30px`}}>📕Каталог</Link><br/>
            <Link to="/graphics" style={{color: `green`, fontSize: 25, lineHeight: `30px`}}>📦Доставка</Link><br/>
            <Link to="/texts" style={{color: `green`, fontSize: 25, lineHeight: `30px`}}>📝Статьи и заметки</Link><br/>
            <Link to="/music" style={{color: `green`, fontSize: 25, lineHeight: `30px`}}>🎸Музыка для чая</Link><br/>
          </div>
          <ul style={{backgroundColor: ``, listStyleType: `none`}} className="tasks__list">
            <a href="#" className="catalog__genres-link" onClick={() => filterNuts()}><li className="tasks__item" style={{fontSize: 25, lineHeight: `30px`}}>🌰Ореховый</li></a>
            {/* <li className="tasks__item" style={{fontSize: 25, lineHeight: `30px`}}>👴Выдержанный</li> */}
            <a href="#" className="catalog__genres-link" onClick={() => filterMusk()}><li className="tasks__item" style={{fontSize: 25, lineHeight: `30px`}}>🐨Животный</li></a>
            <a href="#" className="catalog__genres-link" onClick={() => filterFruit()}><li className="tasks__item" style={{fontSize: 25, lineHeight: `30px`}}>🍑Сухофруктовый</li></a>
            <a href="#" className="catalog__genres-link" onClick={() => filterBerries()}><li className="tasks__item" style={{fontSize: 25, lineHeight: `30px`}}>🫐Ягодный</li></a>
            <a href="#" className="catalog__genres-link" onClick={() => filterWooden()}><li className="tasks__item">🪵Древесный</li></a>
            <a href="#" className="catalog__genres-link" onClick={() => filterResin()}><li className="tasks__item">🌲Смолистый</li></a>
            <li className="tasks__item" style={{fontSize: 25, lineHeight: `30px`}}>🧂Минеральный</li>
            <a href="#" className="catalog__genres-link" onClick={() => filterCamphor()}><li className="tasks__item" style={{fontSize: 25, lineHeight: `30px`}}>🙏Камфорный</li></a>
            <li className="tasks__item" style={{fontSize: 25, lineHeight: `30px`}}>🥧Кондитерский</li>
            <a href="#" className="catalog__genres-link" onClick={() => filterChoco()}><li className="tasks__item" style={{fontSize: 25, lineHeight: `30px`}}>🍫Шоколадный</li></a>
            <a href="#" className="catalog__genres-link" onClick={() => filterHvoya()}><li className="tasks__item" style={{fontSize: 25, lineHeight: `30px`}}>🌲Хвойный</li></a>
          </ul>
        </div>

        {/* <h1 className="visually-hidden">WTW</h1>
        <ul style={{backgroundColor: `indigo`}} className="tasks__list">
          <li className="tasks__item">Ореховый</li>
          <li className="tasks__item">Выдержанный</li>
          <li className="tasks__item">Сливочный</li>
          <li className="tasks__item">Сухофруктовый</li>
          <li className="tasks__item">Ягодный</li>
          <a href="#" className="catalog__genres-link" onClick={() => filterWooden()}><li className="tasks__item">Древесный</li></a>
          <a href="#" className="catalog__genres-link" onClick={() => filterResin()}><li className="tasks__item">Смолистый</li></a>
          <li className="tasks__item">Минеральный</li>
          <li className="tasks__item">Камфорный</li>
          <li className="tasks__item">Кондитерский</li>
          <li className="tasks__item">Шоколадный</li>
          <li className="tasks__item">Хвойный</li>
        </ul> */}

      </section>

      <div className="page-content" id="page-content">
        <section className="catalog">
          <h2 className="catalog__title visually-hidden">Catalog</h2>

          <ul className="catalog__genres-list">
            <li className="catalog__genres-item catalog__genres-item--active">
              <a href="#" className="catalog__genres-link" onClick={() => filterAllTea()}>Все чаи</a>
            </li>
            <li className="catalog__genres-item catalog__genres-item--active">
              <a style={{color: `indigo`}} href="#" className="catalog__genres-link" onClick={() => filterShu()}>Шу-пуэр</a>
            </li>
            <li className="catalog__genres-item catalog__genres-item--active">
              <a style={{color: `indigo`}} href="#" className="catalog__genres-link" onClick={() => filterBlack()}>Хэйча</a>
            </li>
            <li className="catalog__genres-item">
              <a href="#" className="catalog__genres-link" onClick={() => filterHaivan()}>Хайвань</a>
            </li>
            <li className="catalog__genres-item">
              <a href="#" className="catalog__genres-link" onClick={() => filterDaI()}>Да И</a>
            </li>
            <li className="catalog__genres-item">
              <a href="#" className="catalog__genres-link" onClick={() => filterLimin()}>Лимин Бацзяотин</a>
            </li>
            <li className="catalog__genres-item">
              <a href="#" className="catalog__genres-link" onClick={() => filterTulin()}>Тулинь</a>
            </li>
            <li className="catalog__genres-item">
              <a href="#" className="catalog__genres-link" onClick={() => filterJunCha()}>Чжун Ча</a>
            </li>
            <li className="catalog__genres-item">
              <a href="#" className="catalog__genres-link" onClick={() => filterUnGen()}>Юн Чжень</a>
            </li>

          </ul>

          <div className="catalog__movies-list">


            <button type="button" style={{width: `100%`}} onClick={() => onSort()}>
              Сортировать по рейтингу
            </button>
            <button type="button" style={{width: `100%`}} onClick={() => onSortByPrice()}>
              Сортировать по цене
            </button>
            <button type="button" style={{width: `100%`}} onClick={() => onSortByYear()}>
              Сортировать по возрасту
            </button>
            <FilmsList films={films} onSort={onSort} />
            {/* {films.map((film) => {
              return <FilmCard key={film.id} film={film} />;
            })} */}


          </div>

          {/* <div className="catalog__more">
            <button className="catalog__button" type="button">Show more</button>
          </div> */}
          {/* <div>Всего чая на: {costAllMyTea} ₽</div> */}
        </section>

        {/* <section>
          <svg width={width} height={height} >
            <g transform={`translate(${margin.left},${margin.top})`}>
              {xScale.ticks().map((tickValue) => (
                <g key={tickValue} transform={`translate(${xScale(tickValue)},0)`} >
                  <line y2={innerHeight} stroke="black" />
                  <text
                    style={{textAnchor: `middle`}}
                    dy=".71em"
                    y={innerHeight + 3}
                  >
                    {tickValue} ₽
                  </text>
                </g>
              ))}
              {yScale.domain().map((tickValue) => (
                <text
                  key={tickValue}
                  style={{textAnchor: `end`}}
                  x={-3}
                  dy=".32em"
                  y={yScale(tickValue) + yScale.bandwidth() / 2}
                >
                  {tickValue}
                </text>
              ))}
              {films.map((tea) => (
                <rect
                  key={tea.name}
                  y={yScale(tea.name)}
                  width={xScale(tea.cost / tea.weight)}
                  // width={500}
                  height={yScale.bandwidth()}
                />
              ))}
            </g>
          </svg>
        </section> */}
        {window.screen.width >= 1024 &&
          <section className="graphics" style={{width: `100%`}} >
            <ScatterPlot style={{width: `100%`}} films={films} />
            <button type="button" style={{width: `100%`}} onClick={() => onSort()}>
              Сортировать по рейтингу
            </button>
            <button type="button" style={{width: `100%`}} onClick={() => onSortByPrice()}>
              Сортировать по цене
            </button>
            <button type="button" style={{width: `100%`}} onClick={() => onSortByYear()}>
              Сортировать по возрасту
            </button>
            <BarChart films={films} />
            <ScatterPlotByYear films={films} />
          </section>
        }
        {}
        <footer className="page-footer">
          <div className="logo">
            <a className="logo__link logo__link--light">
              <span className="logo__letter logo__letter--1">W</span>
              <span className="logo__letter logo__letter--2">T</span>
              <span className="logo__letter logo__letter--3">D</span>
            </a>
          </div>

          <div className="copyright">
            <p>© 2024 What to drink Ltd.</p>
          </div>
        </footer>
      </div>
    </>
  );
};


MainPage.propTypes = {
  films: PropTypes.arrayOf(Film).isRequired,
  onSort: PropTypes.func.isRequired,
  onSortByPrice: PropTypes.func.isRequired,
  onSortByYear: PropTypes.func.isRequired
  // films: PropTypes.array.isRequired,
};

export default MainPage;
