import { color } from 'chart.js/helpers';
import React from 'react';
import {Link} from "react-router-dom";
// import PropTypes from 'prop-types';


const MyTextPage = () => {

  return (
    <div className="user-page">
      <header className="page-header user-page__head">
        <div className="logo">
          <Link to="/" className="logo__link">
            <span className="logo__letter logo__letter--1">W</span>
            <span className="logo__letter logo__letter--2">T</span>
            <span className="logo__letter logo__letter--3">D</span>
          </Link>
        </div>

        <h1 className="page-title user-page__title">Статьи/Заметки</h1>

        <div className="user-block">
          <div className="user-block__avatar">
            <img src="img/ban006.jpg" alt="User avatar" width="63" height="63" />
          </div>
        </div>
      </header>

      <section className="catalog">
        <h2 className="catalog__title visually-hidden">Catalog</h2>

        <div style={{color:'black'}}>
        <article><p><h3>Зачем сайт, магазин?</h3>
          Для продажи чая. Для коммуникации, в т.ч. о чае. Для развития моих навыков web-разработчика, контент (прости, Господи) мейкера, творческих способностей.
        </p></article>
        <article><p>
          <h3>Профили шу-пуэра.</h3>
          Я выделяю для себя следующие вкусоароматические профили:
          Ореховый
          Животный
          Сухофруктовый/фруктовый
          Ягодный
          Древесный
          Смолистый
          Минеральный
          Камфорный
          Кондитерский
          Шоколадный
          Хвойный
          Библиотечный
          иные
          Деление условно, т.к. многие «шухи» хочется отнести сразу к нескольким, бывает, что и трём-четырём-пяти категориям. Смысл подобного деления: каждая шу выше начального уровня хороша чем-то своим, обладает индивидуальным или , на худой конец, узнаваемым профилем. И каждый профиль может быть хорошо и даже, будто-бы, идеально реализован – вах-вах! (пьёшь и думаешь, а, скорее, не думаешь, а наслаждаешься).
        </p></article>
        <article><p>
          <h3>Топ чайных людей, проектов, мест.</h3>
          Два «чайных небожителя» – Антон Дмитращук и Василий Лебедев. Люди, которые меня восхищают, из других сфер – два для соответствия (так их больше)  – Борис Гребенщиков и Джастин Сандеркое (Justin Sandercoe).
        </p></article>
        <article><p>
          <h3>Ресурсы, рассказывающие о чае.</h3>
          Пока два – старинный агрегатор tea-terra.ru и t.me/teawall. Первый содержит огромное количество текстов по всем чайным тематикам. Второй – хотя недавно (к моему сожалению) лишился домена (адреса своего сайта), по каналу в телеграмме предлагает образцовое сочетание материалов по их длине, читаемости, разнообразию. «Не перегружая, но развивая»
        </p></article>
      </div><div>

      </div>
      </section>

      <footer className="page-footer">
        <div className="logo">
          <Link to="/" className="logo__link logo__link--light">
            <span className="logo__letter logo__letter--1">W</span>
            <span className="logo__letter logo__letter--2">T</span>
            <span className="logo__letter logo__letter--3">D</span>
          </Link>
        </div>

        <div className="copyright">
          <p>© 2024 What to drink Ltd.</p>
        </div>
      </footer>
    </div>
  );
};


export default MyTextPage;
