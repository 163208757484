import React from 'react';
import {scaleBand, scaleLinear, max, extent} from 'd3';
import {AxisBottom} from '../ScatterPlotByYear/components/AxisBottom';
import {AxisLeft} from '../ScatterPlotByYear/components/AxisLeft';
import {Marks} from '../ScatterPlotByYear/components/Marks';

const height = 600;
const width = 1300;
const margin = {top: 50, right: 30, bottom: 200, left: 150};


export const ScatterPlotByYear = ({films}) => {

  const innerHeight = height - margin.top - margin.bottom;
  const innerWidth = width - margin.left - margin.right;

  const yScale = scaleLinear()
    .domain(extent(films, (tea) => tea.profileQuality[4].rating))
    .range([innerHeight, 0])
    .nice();

  const xScale = scaleLinear()
    .domain(extent(films, (tea) => tea.released))
    .range([0, innerWidth])
    .nice();

  return (
    <section>
      <svg width={width} height={height} >
        <g transform={`translate(${margin.left},${margin.top})`}>
          <AxisBottom xScale={xScale} innerHeight={innerHeight}/>
          <text
            // x={-60}
            // y={innerHeight / 2}
            fontSize={30}
            fill='green'
            textDecoration='underline'
            transform={`translate(${-60},${innerHeight / 1.5}) rotate(-90)`}
            // textAnchor='middle'
          >оценка чая</text>
          <AxisLeft yScale={yScale} innerWidth={innerWidth}/>
          <text
            x={innerWidth / 2 - margin.left / 2 - margin.right}
            y={innerHeight + 60}
            fontSize={30}
            fill='green'
            textDecoration='underline'
            // textAnchor='middle'
          >год выпуска</text>
          <Marks films={films} xScale={xScale} yScale={yScale}/>


        </g>
      </svg>
    </section>
  );

};
