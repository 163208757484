export default [
  {
    "id": 1,
    "name": `Цзиньхуншань Цзюй Бао («Собирая сокровища») 2009`,
    "posterImage": `img/zinhunshan.jpg`,
    "previewImage": `img/zinhunshan_1.jpg`,
    "backgroundImage": `img/the-grand-budapest-hotel-bg.jpg`,
    "background_color": `#ffffff`,
    "video_link": `https://download.blender.org/durian/trailer/sintel_trailer-480p.mp4`,
    "preview_video_link": `https://download.blender.org/durian/trailer/sintel_trailer-480p.mp4`,
    "description": `Чай с выдержанным, фруктовым профилем, представитель условной "новой школы", очень приятный по цене.`,
    "genre": `Гуцзюньхао`,
    "released": 2009,
    "is_black": false,
    "cost": 1540,
    "weight": 357,
    "amount": 1750,
    "profile": [
      {
        id: 1,
        tone: `фруктовый`,
        markers: `абрикос, фрукты-гриль, сладкий мультифрукт`
      },
      {
        id: 2,
        tone: `выдержанный`,
        markers: ``
      },
      {
        id: 3,
        tone: `ореховый`,
        markers: `фундук, миндаль`
      }
    ],
    "profileQuality": [
      {
        id: 1,
        quality: `Глубина/насыщенность`,
        rating: 4,
      },
      {
        id: 2,
        quality: `Чистота вкуса`,
        rating: 3,
      },
      {
        id: 3,
        quality: `Аромат`,
        rating: 4,
      },
      {
        id: 4,
        quality: `Гладкость/текстура вкуса`,
        rating: 4,
      },
      {
        id: 5,
        quality: `Общая оценка`,
        rating: 3.75,
      }
    ],
    "isRound": true,
  },
  {
    "id": 7,
    "name": `Гучаюань Мицзин Гу Ча 2021`,
    "posterImage": `img/gushuuan.jpg`,
    "previewImage": `img/gushuuan_1.jpg`,
    "backgroundImage": `img/the-grand-budapest-hotel-bg.jpg`,
    "background_color": `#ffffff`,
    "video_link": `https://some-link`,
    "preview_video_link": `https://some-link`,
    "description": `Чай с мощной, запоминающейся ароматикой - преобладают лесные тона (костёр, смола, ель). Хорошо сложен, вкус в смолистых тонах дополняет аромат. Со временем становится более питким и понятным на вкус. Интересно, чем бы этой чай удивил через 10 лет хорошего хранения.`,
    "genre": `Comedy`,
    "released": 2021,
    "is_black": false,
    "cost": 2740,
    "weight": 357,
    "amount": 1050,
    "profile": [
      {
        id: 1,
        tone: `смолистый`,
        markers: `смола хвойных пород, лыжная мазь`
      },
      {
        id: 2,
        tone: `древесный/лесной`,
        markers: `костёр, хвоя, ель`
      },
      {
        id: 3,
        tone: `погреб/чердак`,
        markers: `чердак частного дома в лесу в Комарово`
      },
      {
        id: 4,
        tone: `минеральный`,
        markers: `йод`
      }
    ],
    "profileQuality": [
      {
        id: 1,
        quality: `Глубина/насыщенность`,
        rating: 4,
      },
      {
        id: 2,
        quality: `Чистота вкуса`,
        rating: 4,
      },
      {
        id: 3,
        quality: `Аромат`,
        rating: 5,
      },
      {
        id: 4,
        quality: `Гладкость/текстура вкуса`,
        rating: 4,
      },
      {
        id: 5,
        quality: `Общая оценка`,
        rating: 4.25,
      }
    ],
    "isRound": true,
  },
  {
    "id": 51,
    "name": `Мэнхай Цзинь Я 2006`,
    "posterImage": `img/menghai_zin_ya.jpg`,
    "previewImage": `img/the-grand-budapest-hotel.jpg`,
    "backgroundImage": `img/the-grand-budapest-hotel-bg.jpg`,
    "background_color": `#ffffff`,
    "video_link": `https://some-link`,
    "preview_video_link": `https://some-link`,
    "description": `Серьёзное сочетание тем выдержанности, шоколадно-кондитерских гунтиновых нот и животных оттенков, присутствует лёгкая камфорность. При заваривании на чуть более мягких температурах исключительно гладкий. Чай без слабых мест, чай классического профиля; хотя, возможно, и не супероригинальный.`,
    "rating": 8.9,
    "scoresCount": 240,
    "director": `Wes Andreson`,
    "starring": [`Bill Murray`, `Edward Norton`, `Jude Law`, `Willem Dafoe`, `Saoirse Ronan`],
    "run_time": 99,
    "genre": `Shu`,
    "released": 2006,
    "is_black": false,
    "cost": 4970,
    "weight": 180,
    "amount": 530,
    "profile": [
      {
        id: 1,
        tone: `шоколадный`,
        markers: `шоколад, какао, конфеты`
      },
      {
        id: 2,
        tone: `кожаный`,
        markers: `кожгалантерейная мастерская`
      },
      {
        id: 3,
        tone: `овощной`,
        markers: `погреб с картофелем, овощи`
      },
      {
        id: 4,
        tone: `библиотечный`,
        markers: `книжный кабинет в царском дворце`
      },
      {
        id: 5,
        tone: `лесной`,
        markers: `лесная чаща, почва, хвоя, камни, мох`
      }
    ],
    "profileQuality": [
      {
        id: 1,
        quality: `Глубина/насыщенность`,
        rating: 4.5,
      },
      {
        id: 2,
        quality: `Чистота вкуса`,
        rating: 4.5,
      },
      {
        id: 3,
        quality: `Аромат`,
        rating: 4.5,
      },
      {
        id: 4,
        quality: `Гладкость/текстура вкуса`,
        rating: 4.5,
      },
      {
        id: 5,
        quality: `Общая оценка`,
        rating: 4.5,
      }
    ],
    "isRound": true,
  },
  {
    "id": 8,
    "name": `Баньчжан Гу Шу 2006`,
    "posterImage": `img/banjan.jpg`,
    "previewImage": `img/ban006.jpg`,
    "backgroundImage": `img/the-grand-budapest-hotel-bg.jpg`,
    "background_color": `#ffffff`,
    "video_link": `https://some-link`,
    "preview_video_link": `https://some-link`,
    "description": `Классика в мире выдержанных шу. Аромат еловых веток сопровождается плотным древесным, со смолинкой и орехами, настоем.`,
    "rating": 8.9,
    "scoresCount": 240,
    "director": `Wes Andreson`,
    "starring": [`Bill Murray`, `Edward Norton`, `Jude Law`, `Willem Dafoe`, `Saoirse Ronan`],
    "run_time": 99,
    "genre": `Comedy`,
    "released": 2006,
    "is_black": false,
    "cost": 1540,
    "weight": 170,
    "amount": 840,
    "profile": [
      {
        id: 1,
        tone: `хвойный`,
        markers: `еловые ветки, сосна, кора, смола`
      },
      {
        id: 2,
        tone: `животный`,
        markers: `мускус, мех`
      },
      {
        id: 3,
        tone: `ореховый`,
        markers: `фундук, миндаль`
      },
      {
        id: 4,
        tone: `шоколадный`,
        markers: `горячий шоколад, кофе`
      }
    ],
    "profileQuality": [
      {
        id: 1,
        quality: `Глубина/насыщенность`,
        rating: 4,
      },
      {
        id: 2,
        quality: `Чистота вкуса`,
        rating: 3.5,
      },
      {
        id: 3,
        quality: `Аромат`,
        rating: 5,
      },
      {
        id: 4,
        quality: `Гладкость/текстура вкуса`,
        rating: 3.75,
      },
      {
        id: 5,
        quality: `Общая оценка`,
        rating: 4.06,
      }
    ],
    "isRound": true,
  },
  {
    "id": 23,
    "name": `Мэнхай Син И Чунь Сян 2012`,
    "posterImage": `img/sinEChun.jpg`,
    "previewImage": `img/sinEChun_1.jpg`,
    "backgroundImage": `img/the-grand-budapest-hotel-bg.jpg`,
    "background_color": `#ffffff`,
    "video_link": `https://some-link`,
    "preview_video_link": `https://some-link`,
    "description": ``,
    "rating": 8.9,
    "scoresCount": 240,
    "director": `Wes Andreson`,
    "starring": [`Bill Murray`, `Edward Norton`, `Jude Law`, `Willem Dafoe`, `Saoirse Ronan`],
    "run_time": 99,
    "genre": `Син И`,
    "released": 2012,
    "is_black": false,
    "cost": 1645,
    "weight": 250,
    "amount": 500,
    "profile": [
      {
        id: 1,
        tone: `фруктовый`,
        markers: `сухофрукты`
      },
      {
        id: 2,
        tone: `древесный`,
        markers: ``
      },
      {
        id: 3,
        tone: `смолистый`,
        markers: ``
      }
    ],
    "profileQuality": [
      {
        id: 1,
        quality: `Глубина/насыщенность`,
        rating: 4,
      },
      {
        id: 2,
        quality: `Чистота вкуса`,
        rating: 3,
      },
      {
        id: 3,
        quality: `Аромат`,
        rating: 4,
      },
      {
        id: 4,
        quality: `Гладкость/текстура вкуса`,
        rating: 4,
      },
      {
        id: 5,
        quality: `Общая оценка`,
        rating: 3.75,
      }
    ],
    "isRound": true,
  },
  {
    "id": 34,
    "name": `Лимин Цзинь Хао 2015`,
    "posterImage": `img/limin_hao.jpg`,
    "previewImage": `img/limin_hao_1.webp`,
    "backgroundImage": `img/the-grand-budapest-hotel-bg.jpg`,
    "background_color": `#ffffff`,
    "video_link": `https://some-link`,
    "preview_video_link": `https://some-link`,
    "description": `Весьма разнообразная вкусовая палитра - от насыщенных шоколадно-ореховых первых проливов к рассыпчатым черносливовым умеренным последним. Интересный чай, обладающий как и характерными "лесными" атрибутами чаёв от Лимин, так и собственной ягодно-сухофруктовой (инжир, чернослив, малина, клюква, вишня) индивидуальностью и текстурой.`,
    "rating": 8.9,
    "scoresCount": 240,
    "director": `Wes Andreson`,
    "starring": [`Bill Murray`, `Edward Norton`, `Jude Law`, `Willem Dafoe`, `Saoirse Ronan`],
    "run_time": 99,
    "genre": `Лимин Бацзяотин`,
    "released": 2015,
    "is_black": false,
    "cost": 2535,
    "weight": 200,
    "amount": 120,
    "profile": [
      {
        id: 1,
        tone: `ягодный`,
        markers: `клюква в сахаре, вишня, барбарис, малина`
      },
      {
        id: 2,
        tone: `подвальный`,
        markers: ``
      },
      {
        id: 3,
        tone: `древесный`,
        markers: `дерево-вишня, чуть хвойных`
      },
      {
        id: 4,
        tone: `сухофруктовый`,
        markers: `инжир, чернослив`
      },
      {
        id: 5,
        tone: `шоколадный`,
        markers: `какао, кофе`
      },
      {
        id: 6,
        tone: `кондитерский`,
        markers: `мёд, леденцы`
      }
    ],
    "profileQuality": [
      {
        id: 1,
        quality: `Глубина/насыщенность`,
        rating: 4.5,
      },
      {
        id: 2,
        quality: `Чистота вкуса`,
        rating: 4,
      },
      {
        id: 3,
        quality: `Аромат`,
        rating: 4.5,
      },
      {
        id: 4,
        quality: `Гладкость/текстура вкуса`,
        rating: 4,
      },
      {
        id: 5,
        quality: `Общая оценка`,
        rating: 4.25,
      }
    ],
    "isRound": true,
  },
  {
    "id": 36,
    "name": `Хайвань Чунь Сян 2018`,
    "posterImage": `img/haivan.jpg`,
    "previewImage": `img/the-grand-budapest-hotel.jpg`,
    "backgroundImage": `img/the-grand-budapest-hotel-bg.jpg`,
    "background_color": `#ffffff`,
    "video_link": `https://some-link`,
    "preview_video_link": `https://some-link`,
    "description": `Неплохой и не такой простой за свои деньги чай. Сухой, довольно богатый букет. В аромате - дерево, ягоды, кондитерка.`,
    "rating": 8.9,
    "scoresCount": 240,
    "director": `Wes Andreson`,
    "starring": [`Bill Murray`, `Edward Norton`, `Jude Law`, `Willem Dafoe`, `Saoirse Ronan`],
    "run_time": 99,
    "genre": `Хайвань`,
    "released": 2018,
    "is_black": false,
    "cost": 2625,
    "weight": 357,
    "amount": 350,
    "profile": [
      {
        id: 1,
        tone: `древесный`,
        markers: ``
      },
      {
        id: 2,
        tone: `ягодный`,
        markers: `смородина, сладкие ягоды`
      },
      {
        id: 3,
        tone: `кондитерский`,
        markers: `брауни`
      },
      {
        id: 4,
        tone: `минеральный`,
        markers: `морская волна`
      },
      {
        id: 5,
        tone: `шоколадный`,
        markers: `с горчинкой шоколад`
      }
    ],
    "profileQuality": [
      {
        id: 1,
        quality: `Глубина/насыщенность`,
        rating: 4,
      },
      {
        id: 2,
        quality: `Чистота вкуса`,
        rating: 4,
      },
      {
        id: 3,
        quality: `Аромат`,
        rating: 4,
      },
      {
        id: 4,
        quality: `Гладкость/текстура вкуса`,
        rating: 3,
      },
      {
        id: 5,
        quality: `Общая оценка`,
        rating: 3.75,
      }
    ],
    "isRound": true,
  },
  {
    "id": 46,
    "name": `Сай Лан До Цзи (Чёрный чай из Яаня) 2017`,
    "posterImage": `img/dodzi.jpg`,
    "previewImage": `img/the-grand-budapest-hotel.jpg`,
    "backgroundImage": `img/the-grand-budapest-hotel-bg.jpg`,
    "background_color": `#ffffff`,
    "video_link": `https://some-link`,
    "preview_video_link": `https://some-link`,
    "description": `Шикарная хэй ча.`,
    "rating": 8.9,
    "scoresCount": 240,
    "director": `Wes Andreson`,
    "starring": [`Bill Murray`, `Edward Norton`, `Jude Law`, `Willem Dafoe`, `Saoirse Ronan`],
    "run_time": 99,
    "genre": `Сай Лан До Цзи`,
    "released": 2017,
    "is_black": true,
    "cost": 3770,
    "weight": 500,
    "amount": 1000,
    "profile": [
      {
        id: 1,
        tone: `древесный`,
        markers: `кора, костёр`
      },
      {
        id: 2,
        tone: `животный`,
        markers: `мех`
      },
      {
        id: 3,
        tone: `шоколадный`,
        markers: `шоколад, кофе, какао`
      },
      {
        id: 4,
        tone: `сухофруктовый`,
        markers: ``
      },
      {
        id: 5,
        tone: `смолистый`,
        markers: ``
      }
    ],
    "profileQuality": [
      {
        id: 1,
        quality: `Глубина/насыщенность`,
        rating: 4,
      },
      {
        id: 2,
        quality: `Чистота вкуса`,
        rating: 4,
      },
      {
        id: 3,
        quality: `Аромат`,
        rating: 4,
      },
      {
        id: 4,
        quality: `Гладкость/текстура вкуса`,
        rating: 4,
      },
      {
        id: 5,
        quality: `Общая оценка`,
        rating: 4,
      }
    ],
    "isRound": false,
  },
  {
    "id": 50,
    "name": `Иу Баопянь "Дольки шу из Иу" 2022`,
    "posterImage": `img/iu.jpg`,
    "previewImage": `img/iu2.webp`,
    "backgroundImage": `img/the-grand-budapest-hotel-bg.jpg`,
    "background_color": `#ffffff`,
    "video_link": `https://some-link`,
    "preview_video_link": `https://some-link`,
    "description": `Характерный и вкусный для данной ценовой категории чай. Довольно редкий профиль - жареные, злаковые тона, много лесных оттенков в аромате - мох, грибы, дождь в чаще. Необычно, довольно аккуратно, вкусно. Достойно получается в термосе, но мне больше понравился при заваривании проливами в чайнике. Маленький квадратик из цельного листа весит 8 гр.`,
    "rating": 8.9,
    "scoresCount": 240,
    "director": `Wes Andreson`,
    "starring": [`Bill Murray`, `Edward Norton`, `Jude Law`, `Willem Dafoe`, `Saoirse Ronan`],
    "run_time": 99,
    "genre": `Shu`,
    "released": 2022,
    "is_black": false,
    "cost": 52,
    "weight": 8,
    "amount": 240,
    "profile": [
      {
        id: 1,
        tone: `жареный`,
        markers: `жареные грибы, орехи, овощи`
      },
      {
        id: 2,
        tone: `животный`,
        markers: `мех`
      },
      {
        id: 3,
        tone: `грибной`,
        markers: `грибы, жареные грибы`
      },
      {
        id: 4,
        tone: `злаковый`,
        markers: `рис, крупы`
      },
      {
        id: 5,
        tone: `древесный`,
        markers: `древесина под дождём, мох`
      }
    ],
    "profileQuality": [
      {
        id: 1,
        quality: `Глубина/насыщенность`,
        rating: 4,
      },
      {
        id: 2,
        quality: `Чистота вкуса`,
        rating: 4,
      },
      {
        id: 3,
        quality: `Аромат`,
        rating: 4,
      },
      {
        id: 4,
        quality: `Гладкость/текстура вкуса`,
        rating: 4,
      },
      {
        id: 5,
        quality: `Общая оценка`,
        rating: 4,
      }
    ],
    "isRound": false,
  },
  {
    "id": 55,
    "name": `Гу Шу Любао Хэйча 2010`,
    "posterImage": `img/lubao.webp`,
    "previewImage": `img/lubao.webp`,
    "backgroundImage": `img/the-grand-budapest-hotel-bg.jpg`,
    "background_color": `#ffffff`,
    "video_link": `https://some-link`,
    "preview_video_link": `https://some-link`,
    "description": `Яркая, мощная хэйча с преобладанием аромата кожи, конгалантерейной мастерской. На втором плане - лесная чаща, почва, хвоя. В сочетании природных тонов (лес) и рукодельных (кожа, книги) - очарование этого чая. Уникально вынослив - выдерживает большое количество проливов.`,
    "rating": 8.9,
    "scoresCount": 240,
    "director": `Wes Andreson`,
    "starring": [`Bill Murray`, `Edward Norton`, `Jude Law`, `Willem Dafoe`, `Saoirse Ronan`],
    "run_time": 99,
    "genre": `Hei`,
    "released": 2010,
    "is_black": true,
    "cost": 1700,
    "weight": 100,
    "amount": 250,
    "profile": [
      {
        id: 1,
        tone: `подвальный`,
        markers: `погреб, подвал`
      },
      {
        id: 2,
        tone: `шоколадный`,
        markers: `шоколадные конфеты, какао, шоколад, пирожные`
      },
      {
        id: 3,
        tone: `животный`,
        markers: `мех, мускус`
      },
      {
        id: 4,
        tone: `камфорный`,
        markers: `камфора`
      }
    ],
    "profileQuality": [
      {
        id: 1,
        quality: `Глубина/насыщенность`,
        rating: 5,
      },
      {
        id: 2,
        quality: `Чистота вкуса`,
        rating: 4,
      },
      {
        id: 3,
        quality: `Аромат`,
        rating: 5,
      },
      {
        id: 4,
        quality: `Гладкость/текстура вкуса`,
        rating: 4,
      },
      {
        id: 5,
        quality: `Общая оценка`,
        rating: 4.5,
      }
    ],
    "isRound": true,
  },
  {
    "id": 56,
    "name": `Ченьсян Любао "Аромат старины" 1801 2017`,
    "posterImage": `img/lubao.webp`,
    "previewImage": `img/lubao.webp`,
    "backgroundImage": `img/the-grand-budapest-hotel-bg.jpg`,
    "background_color": `#ffffff`,
    "video_link": `https://some-link`,
    "preview_video_link": `https://some-link`,
    "description": `Приятная хэйча с сочетанием почвенных, кожаных, ореховых, животных вкусоароматических маркеров`,
    "rating": 8.9,
    "scoresCount": 240,
    "director": `Wes Andreson`,
    "starring": [`Bill Murray`, `Edward Norton`, `Jude Law`, `Willem Dafoe`, `Saoirse Ronan`],
    "run_time": 99,
    "genre": `Hei`,
    "released": 2017,
    "is_black": true,
    "cost": 835,
    "weight": 100,
    "amount": 380,
    "profile": [
      {
        id: 1,
        tone: `ореховый`,
        markers: ``
      },
      {
        id: 2,
        tone: `почвенный/землистый`,
        markers: `почва, листья, торф`
      },
      {
        id: 3,
        tone: `копчёный`,
        markers: `копоть, дым, сало`
      },
      {
        id: 4,
        tone: `шоколадный`,
        markers: `шоколад`
      },
      {
        id: 5,
        tone: `животный`,
        markers: `сало, зажарка, копчение`
      }
    ],
    "profileQuality": [
      {
        id: 1,
        quality: `Глубина/насыщенность`,
        rating: 4,
      },
      {
        id: 2,
        quality: `Чистота вкуса`,
        rating: 4,
      },
      {
        id: 3,
        quality: `Аромат`,
        rating: 4,
      },
      {
        id: 4,
        quality: `Гладкость/текстура вкуса`,
        rating: 4,
      },
      {
        id: 5,
        quality: `Общая оценка`,
        rating: 4,
      }
    ],
    "isRound": true,
  },
  {
    "id": 48,
    "name": `Хайвань Лао Тун Чжи "Старый Товарищ" 9978 2021`,
    "posterImage": `img/tunji.jpg`,
    "previewImage": `img/the-grand-budapest-hotel.jpg`,
    "backgroundImage": `img/the-grand-budapest-hotel-bg.jpg`,
    "background_color": `#ffffff`,
    "video_link": `https://some-link`,
    "preview_video_link": `https://some-link`,
    "description": `Довольно бодрый. Древесная тематика (оригинальная, нескучная), чуть мёда для сладости. Хороший баланс горечи и сладости.`,
    "rating": 8.9,
    "scoresCount": 240,
    "director": `Wes Andreson`,
    "starring": [`Bill Murray`, `Edward Norton`, `Jude Law`, `Willem Dafoe`, `Saoirse Ronan`],
    "run_time": 99,
    "genre": `Хайвань`,
    "released": 2021,
    "is_black": false,
    "cost": 1990,
    "weight": 357,
    "amount": 340,
    "profile": [
      {
        id: 1,
        tone: `древесный`,
        markers: `древесина, опавшая листва`
      },
      {
        id: 2,
        tone: `йодистый`,
        markers: `йод, пощипывание языка`
      },
      {
        id: 3,
        tone: `кондитерский`,
        markers: `мёд, брауни`
      }
    ],
    "profileQuality": [
      {
        id: 1,
        quality: `Глубина/насыщенность`,
        rating: 4,
      },
      {
        id: 2,
        quality: `Чистота вкуса`,
        rating: 3,
      },
      {
        id: 3,
        quality: `Аромат`,
        rating: 4,
      },
      {
        id: 4,
        quality: `Гладкость/текстура вкуса`,
        rating: 3,
      },
      {
        id: 5,
        quality: `Общая оценка`,
        rating: 3.5,
      }
    ],
    "isRound": true,
  },
  {
    "id": 57,
    "name": `Тулинь "607" 2020г кирпич (250г)`,
    "posterImage": `img/tulin_kirp.jpg`,
    "previewImage": `img/the-grand-budapest-hotel.jpg`,
    "backgroundImage": `img/the-grand-budapest-hotel-bg.jpg`,
    "background_color": `#ffffff`,
    "video_link": `https://some-link`,
    "preview_video_link": `https://some-link`,
    "description": `Довольно бодрый. Древесная тематика (оригинальная, нескучная), чуть мёда для сладости. Хороший баланс горечи и сладости.`,
    "rating": 8.9,
    "scoresCount": 240,
    "director": `Wes Andreson`,
    "starring": [`Bill Murray`, `Edward Norton`, `Jude Law`, `Willem Dafoe`, `Saoirse Ronan`],
    "run_time": 99,
    "genre": `Тулинь`,
    "released": 2020,
    "is_black": false,
    "cost": 1430,
    "weight": 250,
    "amount": 730,
    "profile": [
      {
        id: 1,
        tone: `смолистый`,
        markers: `смола хвойных пород`
      },
      {
        id: 2,
        tone: `йодистый`,
        markers: `йод, пощипывание языка`
      },
      {
        id: 3,
        tone: `кондитерский`,
        markers: `мёд, брауни`
      }
    ],
    "profileQuality": [
      {
        id: 1,
        quality: `Глубина/насыщенность`,
        rating: 4,
      },
      {
        id: 2,
        quality: `Чистота вкуса`,
        rating: 4,
      },
      {
        id: 3,
        quality: `Аромат`,
        rating: 4.5,
      },
      {
        id: 4,
        quality: `Гладкость/текстура вкуса`,
        rating: 4,
      },
      {
        id: 5,
        quality: `Общая оценка`,
        rating: 4.125,
      }
    ],
    "isRound": false,
  },
  {
    "id": 58,
    "name": `Шу кирпич Да И 7562 2022г (250г)`,
    "posterImage": `img/dai7562.jpg`,
    "previewImage": `img/dai7562_1.jpg`,
    "backgroundImage": `img/the-grand-budapest-hotel-bg.jpg`,
    "background_color": `#ffffff`,
    "video_link": `https://some-link`,
    "preview_video_link": `https://some-link`,
    "description": ``,
    "rating": 8.9,
    "scoresCount": 240,
    "director": `Wes Andreson`,
    "starring": [`Bill Murray`, `Edward Norton`, `Jude Law`, `Willem Dafoe`, `Saoirse Ronan`],
    "run_time": 99,
    "genre": `Да И`,
    "released": 2022,
    "is_black": false,
    "cost": 1990,
    "weight": 250,
    "amount": 250,
    "profile": [
      {
        id: 1,
        tone: `фруктовый`,
        markers: `абрикос, курага, фрукты-гриль`
      },
      {
        id: 2,
        tone: `шоколадный`,
        markers: `шоколад, какао, конфеты`
      },
      {
        id: 3,
        tone: `ореховый`,
        markers: `фундук, ореховая смесь`
      },
      {
        id: 4,
        tone: `древесный`,
        markers: `древесина`
      },
      {
        id: 5,
        tone: `минеральный`,
        markers: `йод, морская волна`
      }
    ],
    "profileQuality": [
      {
        id: 1,
        quality: `Глубина/насыщенность`,
        rating: 4,
      },
      {
        id: 2,
        quality: `Чистота вкуса`,
        rating: 3.5,
      },
      {
        id: 3,
        quality: `Аромат`,
        rating: 4.5,
      },
      {
        id: 4,
        quality: `Гладкость/текстура вкуса`,
        rating: 4,
      },
      {
        id: 5,
        quality: `Общая оценка`,
        rating: 4,
      }
    ],
    "isRound": false,
  },
  {
    "id": 59,
    "name": `Шу блин Бу Лан Юнь Вэй 2010г (250г)`,
    "posterImage": `img/bulan2010.jpg`,
    "previewImage": `img/bulan2010_1.jpg`,
    "backgroundImage": `img/the-grand-budapest-hotel-bg.jpg`,
    "background_color": `#ffffff`,
    "video_link": `https://some-link`,
    "preview_video_link": `https://some-link`,
    "description": ``,
    "rating": 8.9,
    "scoresCount": 240,
    "director": `Wes Andreson`,
    "starring": [`Bill Murray`, `Edward Norton`, `Jude Law`, `Willem Dafoe`, `Saoirse Ronan`],
    "run_time": 99,
    "genre": `Shu`,
    "released": 2010,
    "is_black": false,
    "cost": 3080,
    "weight": 250,
    "amount": 250,
    "profile": [
      {
        id: 1,
        tone: `шоколадный`,
        markers: `шоколад, горячий шоколад`
      },
      {
        id: 2,
        tone: `древесный/лесной`,
        markers: `лес, костёр, дым`
      },
      {
        id: 3,
        tone: `смолистый`,
        markers: `смола`
      },
      {
        id: 4,
        tone: `погреб`,
        markers: `погреб с овощами`
      },
      {
        id: 3,
        tone: `кожаный`,
        markers: `кожгалантерея`
      }
    ],
    "profileQuality": [
      {
        id: 1,
        quality: `Глубина/насыщенность`,
        rating: 5,
      },
      {
        id: 2,
        quality: `Чистота вкуса`,
        rating: 4,
      },
      {
        id: 3,
        quality: `Аромат`,
        rating: 4,
      },
      {
        id: 4,
        quality: `Гладкость/текстура вкуса`,
        rating: 4,
      },
      {
        id: 5,
        quality: `Общая оценка`,
        rating: 4.25,
      }
    ],
    "isRound": true,
  },
  {
    "id": 60,
    "name": `Шу блин Чжун Ча Красная печать 1-й сорт 2003г (357г)`,
    "posterImage": `img/juncha2003.jpg`,
    "previewImage": `img/juncha2003_1.jpg`,
    "backgroundImage": `img/the-grand-budapest-hotel-bg.jpg`,
    "background_color": `#ffffff`,
    "video_link": `https://some-link`,
    "preview_video_link": `https://some-link`,
    "description": ``,
    "rating": 8.9,
    "scoresCount": 240,
    "director": `Wes Andreson`,
    "starring": [`Bill Murray`, `Edward Norton`, `Jude Law`, `Willem Dafoe`, `Saoirse Ronan`],
    "run_time": 99,
    "genre": `Чжун Ча`,
    "released": 2003,
    "is_black": false,
    "cost": 3425,
    "weight": 343,
    "amount": 1700,
    "profile": [
      {
        id: 1,
        tone: `древесный`,
        markers: `древесина, опавшая листва`
      },
      {
        id: 2,
        tone: `йодистый`,
        markers: `йод, пощипывание языка`
      },
      {
        id: 3,
        tone: `кондитерский`,
        markers: `мёд, брауни`
      }
    ],
    "profileQuality": [
      {
        id: 1,
        quality: `Глубина/насыщенность`,
        rating: 4,
      },
      {
        id: 2,
        quality: `Чистота вкуса`,
        rating: 4,
      },
      {
        id: 3,
        quality: `Аромат`,
        rating: 5,
      },
      {
        id: 4,
        quality: `Гладкость/текстура вкуса`,
        rating: 4,
      },
      {
        id: 5,
        quality: `Общая оценка`,
        rating: 4.25,
      }
    ],
    "isRound": true,
  },
  {
    "id": 61,
    "name": `Сигуй King of Glory 2016г (357г)`,
    "posterImage": `img/sigui2016.jpg`,
    "previewImage": `img/sigui2016_1.jpg`,
    "backgroundImage": `img/the-grand-budapest-hotel-bg.jpg`,
    "background_color": `#ffffff`,
    "video_link": `https://some-link`,
    "preview_video_link": `https://some-link`,
    "description": ``,
    "rating": 8.9,
    "scoresCount": 240,
    "director": `Wes Andreson`,
    "starring": [`Bill Murray`, `Edward Norton`, `Jude Law`, `Willem Dafoe`, `Saoirse Ronan`],
    "run_time": 99,
    "genre": `Shu`,
    "released": 2016,
    "is_black": false,
    "cost": 1540,
    "weight": 350,
    "amount": 700,
    "profile": [
      {
        id: 1,
        tone: `фруктовый`,
        markers: `курага, фрукты-гриль`
      },
      {
        id: 2,
        tone: `древесный`,
        markers: ``
      },
      {
        id: 3,
        tone: `кондитерский`,
        markers: `брауни`
      }
    ],
    "profileQuality": [
      {
        id: 1,
        quality: `Глубина/насыщенность`,
        rating: 4,
      },
      {
        id: 2,
        quality: `Чистота вкуса`,
        rating: 4,
      },
      {
        id: 3,
        quality: `Аромат`,
        rating: 4,
      },
      {
        id: 4,
        quality: `Гладкость/текстура вкуса`,
        rating: 4,
      },
      {
        id: 5,
        quality: `Общая оценка`,
        rating: 4,
      }
    ],
    "isRound": true,
  },
  {
    "id": 62,
    "name": `Гу Юнь Чунь Сян 2018г (357г)`,
    "posterImage": `img/guun2018.jpg`,
    "previewImage": `img/guun2018_1.jpg`,
    "backgroundImage": `img/the-grand-budapest-hotel-bg.jpg`,
    "background_color": `#ffffff`,
    "video_link": `https://some-link`,
    "preview_video_link": `https://some-link`,
    "description": `По профилю - молодой брат Банчжан Гу Шу 2006`,
    "rating": 8.9,
    "scoresCount": 240,
    "director": `Wes Andreson`,
    "starring": [`Bill Murray`, `Edward Norton`, `Jude Law`, `Willem Dafoe`, `Saoirse Ronan`],
    "run_time": 99,
    "genre": `Shu`,
    "released": 2018,
    "is_black": false,
    "cost": 2055,
    "weight": 350,
    "amount": 350,
    "profile": [
      {
        id: 1,
        tone: `хвойный`,
        markers: `хвойные породы деревьев`
      },
      {
        id: 2,
        tone: `смолистый`,
        markers: `смола`
      },
      {
        id: 3,
        tone: `животный`,
        markers: `мех, мускус`
      },
      {
        id: 4,
        tone: `шоколадный`,
        markers: `шоколад, какао, трюфели`
      },
      {
        id: 5,
        tone: `камфорный`,
        markers: `камфора`
      },
      {
        id: 6,
        tone: `грибной`,
        markers: `грибной бульон`
      }
    ],
    "profileQuality": [
      {
        id: 1,
        quality: `Глубина/насыщенность`,
        rating: 4,
      },
      {
        id: 2,
        quality: `Чистота вкуса`,
        rating: 4,
      },
      {
        id: 3,
        quality: `Аромат`,
        rating: 4,
      },
      {
        id: 4,
        quality: `Гладкость/текстура вкуса`,
        rating: 3,
      },
      {
        id: 5,
        quality: `Общая оценка`,
        rating: 3.75,
      }
    ],
    "isRound": true,
  },
  {
    "id": 63,
    "name": `Кунцюэ Шэнтай 2012г (357г)`,
    "posterImage": `img/kunzue2012.jpg`,
    "previewImage": `img/the-grand-budapest-hotel.jpg`,
    "backgroundImage": `img/the-grand-budapest-hotel-bg.jpg`,
    "background_color": `#ffffff`,
    "video_link": `https://some-link`,
    "preview_video_link": `https://some-link`,
    "description": ``,
    "rating": 8.9,
    "scoresCount": 240,
    "director": `Wes Andreson`,
    "starring": [`Bill Murray`, `Edward Norton`, `Jude Law`, `Willem Dafoe`, `Saoirse Ronan`],
    "run_time": 99,
    "genre": `Shu`,
    "released": 2012,
    "is_black": false,
    "cost": 2225,
    "weight": 350,
    "amount": 350,
    "profile": [
      {
        id: 1,
        tone: `древесный`,
        markers: `древесина, опавшая листва`
      },
      {
        id: 2,
        tone: `йодистый`,
        markers: `йод, пощипывание языка`
      },
      {
        id: 3,
        tone: `кондитерский`,
        markers: `мёд, брауни`
      }
    ],
    "profileQuality": [
      {
        id: 1,
        quality: `Глубина/насыщенность`,
        rating: 4,
      },
      {
        id: 2,
        quality: `Чистота вкуса`,
        rating: 4,
      },
      {
        id: 3,
        quality: `Аромат`,
        rating: 5,
      },
      {
        id: 4,
        quality: `Гладкость/текстура вкуса`,
        rating: 4,
      },
      {
        id: 5,
        quality: `Общая оценка`,
        rating: 3.6,
      }
    ],
    "isRound": true,
  },
  {
    "id": 64,
    "name": `Ма Ань Шань 2020г (357г)`,
    "posterImage": `img/maanshan.jpg`,
    "previewImage": `img/the-grand-budapest-hotel.jpg`,
    "backgroundImage": `img/the-grand-budapest-hotel-bg.jpg`,
    "background_color": `#ffffff`,
    "video_link": `https://some-link`,
    "preview_video_link": `https://some-link`,
    "description": ``,
    "rating": 8.9,
    "scoresCount": 240,
    "director": `Wes Andreson`,
    "starring": [`Bill Murray`, `Edward Norton`, `Jude Law`, `Willem Dafoe`, `Saoirse Ronan`],
    "run_time": 99,
    "genre": `Shu`,
    "released": 2020,
    "is_black": false,
    "cost": 2400,
    "weight": 350,
    "amount": 700,
    "profile": [
      {
        id: 1,
        tone: `древесный`,
        markers: `древесина, опавшая листва`
      },
      {
        id: 2,
        tone: `йодистый`,
        markers: `йод, пощипывание языка`
      },
      {
        id: 3,
        tone: `кондитерский`,
        markers: `мёд, брауни`
      }
    ],
    "profileQuality": [
      {
        id: 1,
        quality: `Глубина/насыщенность`,
        rating: 4,
      },
      {
        id: 2,
        quality: `Чистота вкуса`,
        rating: 4,
      },
      {
        id: 3,
        quality: `Аромат`,
        rating: 5,
      },
      {
        id: 4,
        quality: `Гладкость/текстура вкуса`,
        rating: 4,
      },
      {
        id: 5,
        quality: `Общая оценка`,
        rating: 3.75,
      }
    ],
    "isRound": true,
  },
  {
    "id": 65,
    "name": `Баньчжан Шентай Бин 2012г (357г)`,
    "posterImage": `img/banjan2012.jpg`,
    "previewImage": `img/banjan2012_1.jpg`,
    "backgroundImage": `img/the-grand-budapest-hotel-bg.jpg`,
    "background_color": `#ffffff`,
    "video_link": `https://some-link`,
    "preview_video_link": `https://some-link`,
    "description": `Интересный представитель "животного" профиля пуэров - не слишком изысканный и сложный, но активный, хорошо сложенный. Мускус (мех), фрукты, перечный шоколад, орехи, немного свекольности, немного сливочности, любопытная текстура - и это, возможно, ещё не всё, что отыщете в нём Вы. Если этот чай не будет продаваться, я не расстроюсь - я просто выпью его сам)`,
    "rating": 8.9,
    "scoresCount": 240,
    "director": `Wes Andreson`,
    "starring": [`Bill Murray`, `Edward Norton`, `Jude Law`, `Willem Dafoe`, `Saoirse Ronan`],
    "run_time": 99,
    "genre": `Shu`,
    "released": 2012,
    "is_black": false,
    "cost": 2570,
    "weight": 350,
    "amount": 1000,
    "profile": [
      {
        id: 1,
        tone: `животный`,
        markers: `мускус, мех, лесная чаща`
      },
      {
        id: 2,
        tone: `фруктовый`,
        markers: `фрукты на костре`
      },
      {
        id: 3,
        tone: `шоколад`,
        markers: `горький шоколад с перцем`
      }
    ],
    "profileQuality": [
      {
        id: 1,
        quality: `Глубина/насыщенность`,
        rating: 4,
      },
      {
        id: 2,
        quality: `Чистота вкуса`,
        rating: 4,
      },
      {
        id: 3,
        quality: `Аромат`,
        rating: 4.5,
      },
      {
        id: 4,
        quality: `Гладкость/текстура вкуса`,
        rating: 4,
      },
      {
        id: 5,
        quality: `Общая оценка`,
        rating: 4.125,
      }
    ],
    "isRound": true,
  },
  {
    "id": 66,
    "name": `Чунь Юэ Ли Мин 2013г (357г)`,
    "posterImage": `img/limin2013.jpg`,
    "previewImage": `img/limin2013_1.jpg`,
    "backgroundImage": `img/the-grand-budapest-hotel-bg.jpg`,
    "background_color": `#ffffff`,
    "video_link": `https://some-link`,
    "preview_video_link": `https://some-link`,
    "description": `Чай с самобытной ароматической композицией - баня, веники, изба, лес (ароматы, знамые почти каждому - тем удивительнее встретить их в настолько очевидном воплощении в чае). Гладкий и лёгкий, при этом насыщенный. Несложный, но добротный по всем вкусовым параметрам.`,
    "rating": 8.9,
    "scoresCount": 240,
    "director": `Wes Andreson`,
    "starring": [`Bill Murray`, `Edward Norton`, `Jude Law`, `Willem Dafoe`, `Saoirse Ronan`],
    "run_time": 99,
    "genre": `Лимин Бацзяотин`,
    "released": 2013,
    "is_black": false,
    "cost": 2915,
    "weight": 350,
    "amount": 350,
    "profile": [
      {
        id: 1,
        tone: `древесный`,
        markers: `баня, банные веники - лиственные, хвойные, поленница, изба, костёр`
      },
      {
        id: 2,
        tone: `шоколадный`,
        markers: `шоколад, какао`
      }

    ],
    "profileQuality": [
      {
        id: 1,
        quality: `Глубина/насыщенность`,
        rating: 4,
      },
      {
        id: 2,
        quality: `Чистота вкуса`,
        rating: 4,
      },
      {
        id: 3,
        quality: `Аромат`,
        rating: 5,
      },
      {
        id: 4,
        quality: `Гладкость/текстура вкуса`,
        rating: 4.5,
      },
      {
        id: 5,
        quality: `Общая оценка`,
        rating: 4.375,
      }
    ],
    "isRound": true,
  },
  {
    "id": 67,
    "name": `Бай Чжи Ча Чжуань 2017г (200г)`,
    "posterImage": `img/chajuan2017.jpg`,
    "previewImage": `img/chajuan2017_1.jpg`,
    "backgroundImage": `img/the-grand-budapest-hotel-bg.jpg`,
    "background_color": `#ffffff`,
    "video_link": `https://some-link`,
    "preview_video_link": `https://some-link`,
    "description": `Чай относится к отчётливому профилю "какао-шоколад", с присущими ему насыщенностью, горчинкой, постоянством. Присутствует также лесная тематика - шерсть, кора и ветки дерева, лёгкая камфорность.`,
    "rating": 8.9,
    "scoresCount": 240,
    "director": `Wes Andreson`,
    "starring": [`Bill Murray`, `Edward Norton`, `Jude Law`, `Willem Dafoe`, `Saoirse Ronan`],
    "run_time": 99,
    "genre": `Shu`,
    "released": 2017,
    "is_black": false,
    "cost": 1885,
    "weight": 200,
    "amount": 180,
    "profile": [
      {
        id: 1,
        tone: `шоколадный`,
        markers: `какао-шоколад, горький шоколад, какао`
      },
      {
        id: 2,
        tone: `лесной`,
        markers: `шерсть, кора деревьев, ветки`
      },
      {
        id: 3,
        tone: `камфорный`,
        markers: `камфора`
      }
    ],
    "profileQuality": [
      {
        id: 1,
        quality: `Глубина/насыщенность`,
        rating: 4.5,
      },
      {
        id: 2,
        quality: `Чистота вкуса`,
        rating: 3.5,
      },
      {
        id: 3,
        quality: `Аромат`,
        rating: 4,
      },
      {
        id: 4,
        quality: `Гладкость/текстура вкуса`,
        rating: 3,
      },
      {
        id: 5,
        quality: `Общая оценка`,
        rating: 3.75,
      }
    ],
    "isRound": false,
  },
  {
    "id": 68,
    "name": `Юн Чжень Цзинь Чжень Бай Лянь 2018г (200г)`,
    "posterImage": `img/bailyan.jpg`,
    "previewImage": `img/bailyan_1.jpg`,
    "backgroundImage": `img/the-grand-budapest-hotel-bg.jpg`,
    "background_color": `#ffffff`,
    "video_link": `https://some-link`,
    "preview_video_link": `https://some-link`,
    "description": `Классика от фабрики Юж Чжень в лучшем своём исполнении. Узнаваемо, но чуть тоньше и значительно разнообразнее.`,
    "rating": 8.9,
    "scoresCount": 240,
    "director": `Wes Andreson`,
    "starring": [`Bill Murray`, `Edward Norton`, `Jude Law`, `Willem Dafoe`, `Saoirse Ronan`],
    "run_time": 99,
    "genre": `Юн Чжень`,
    "released": 2018,
    "is_black": false,
    "cost": 1030,
    "weight": 200,
    "amount": 200,
    "profile": [
      {
        id: 1,
        tone: `кондитерский/шоколадный`,
        markers: `шоколад, конфеты, выпечка`
      },
      {
        id: 2,
        tone: `древесина`,
        markers: `древесина, бумага`
      },
      {
        id: 3,
        tone: `землистый`,
        markers: `аромат влажной лесной почвы`
      },
      {
        id: 4,
        tone: `минеральный`,
        markers: `аромат морской волны, йод`
      },
      {
        id: 5,
        tone: `погреб`,
        markers: `погреб с овощами`
      },
      {
        id: 6,
        tone: `овощной`,
        markers: `картофель, батат`
      }
    ],
    "profileQuality": [
      {
        id: 1,
        quality: `Глубина/насыщенность`,
        rating: 4,
      },
      {
        id: 2,
        quality: `Чистота вкуса`,
        rating: 3.5,
      },
      {
        id: 3,
        quality: `Аромат`,
        rating: 4.5,
      },
      {
        id: 4,
        quality: `Гладкость/текстура вкуса`,
        rating: 4,
      },
      {
        id: 5,
        quality: `Общая оценка`,
        rating: 4,
      }
    ],
    "isRound": false,
  },
  {
    "id": 69,
    "name": `Гу Шу Ча Чжуань 2015г (250г)`,
    "posterImage": `img/gushu2015.jpg`,
    "previewImage": `img/gushu2015_1.jpg`,
    "backgroundImage": `img/the-grand-budapest-hotel-bg.jpg`,
    "background_color": `#ffffff`,
    "video_link": `https://some-link`,
    "preview_video_link": `https://some-link`,
    "description": ``,
    "rating": 8.9,
    "scoresCount": 240,
    "director": `Wes Andreson`,
    "starring": [`Bill Murray`, `Edward Norton`, `Jude Law`, `Willem Dafoe`, `Saoirse Ronan`],
    "run_time": 99,
    "genre": `Shu`,
    "released": 2015,
    "is_black": false,
    "cost": 1540,
    "weight": 250,
    "amount": 250,
    "profile": [
      {
        id: 1,
        tone: `древесный`,
        markers: `древесина, опавшая листва`
      },
      {
        id: 2,
        tone: `йодистый`,
        markers: `йод, пощипывание языка`
      },
      {
        id: 3,
        tone: `кондитерский`,
        markers: `мёд, брауни`
      }
    ],
    "profileQuality": [
      {
        id: 1,
        quality: `Глубина/насыщенность`,
        rating: 4,
      },
      {
        id: 2,
        quality: `Чистота вкуса`,
        rating: 4,
      },
      {
        id: 3,
        quality: `Аромат`,
        rating: 3,
      },
      {
        id: 4,
        quality: `Гладкость/текстура вкуса`,
        rating: 3.5,
      },
      {
        id: 5,
        quality: `Общая оценка`,
        rating: 3.625,
      }
    ],
    "isRound": false,
  },
  {
    "id": 70,
    "name": `Лао Ча Тоу Ча Чжуань 2014г (250г)`,
    "posterImage": `img/chatou2014.jpg`,
    "previewImage": `img/gushu2015_1.jpg`,
    "backgroundImage": `img/the-grand-budapest-hotel-bg.jpg`,
    "background_color": `#ffffff`,
    "video_link": `https://some-link`,
    "preview_video_link": `https://some-link`,
    "description": `Чай любопытен своим нейтрально-овощным профилем (чистый флегматик в мире шу). В дополнение, или как следствие, заваривается очень постепенно. Больше поход на шу сильной прессовки, нежели на чайные головы, хотя скорость экстракции и вид чайного дна всё же говорят об обратном.`,
    "rating": 8.9,
    "scoresCount": 240,
    "director": `Wes Andreson`,
    "starring": [`Bill Murray`, `Edward Norton`, `Jude Law`, `Willem Dafoe`, `Saoirse Ronan`],
    "run_time": 99,
    "genre": `Shu`,
    "released": 2014,
    "is_black": false,
    "cost": 1715,
    "weight": 250,
    "amount": 250,
    "profile": [
      {
        id: 1,
        tone: `камфорный`,
        markers: `камфора`
      },
      {
        id: 2,
        tone: `овощной`,
        markers: `картофель, свекла`
      },
      {
        id: 3,
        tone: `погреб/подвал`,
        markers: `погреб`
      },
      {
        id: 4,
        tone: `злаковый`,
        markers: `рис`
      },
      {
        id: 5,
        tone: `ореховый`,
        markers: `фундук`
      }
    ],
    "profileQuality": [
      {
        id: 1,
        quality: `Глубина/насыщенность`,
        rating: 4,
      },
      {
        id: 2,
        quality: `Чистота вкуса`,
        rating: 4,
      },
      {
        id: 3,
        quality: `Аромат`,
        rating: 4.5,
      },
      {
        id: 4,
        quality: `Гладкость/текстура вкуса`,
        rating: 3.5,
      },
      {
        id: 5,
        quality: `Общая оценка`,
        rating: 4,
      }
    ],
    "isRound": false,
  },
  {
    "id": 71,
    "name": `Цзан мин чжен пинь 2015г кирпич (600г)`,
    "posterImage": `img/zanmin2015.jpg`,
    "previewImage": `img/zanmin2015_1.jpg`,
    "backgroundImage": `img/the-grand-budapest-hotel-bg.jpg`,
    "background_color": `#ffffff`,
    "video_link": `https://some-link`,
    "preview_video_link": `https://some-link`,
    "description": ``,
    "rating": 8.9,
    "scoresCount": 240,
    "director": `Wes Andreson`,
    "starring": [`Bill Murray`, `Edward Norton`, `Jude Law`, `Willem Dafoe`, `Saoirse Ronan`],
    "run_time": 99,
    "genre": `Shu`,
    "released": 2015,
    "is_black": true,
    "cost": 3770,
    "weight": 600,
    "amount": 1200,
    "profile": [
      {
        id: 1,
        tone: `древесный`,
        markers: `древесина, опавшая листва`
      },
      {
        id: 2,
        tone: `йодистый`,
        markers: `йод, пощипывание языка`
      },
      {
        id: 3,
        tone: `кондитерский`,
        markers: `мёд, брауни`
      }
    ],
    "profileQuality": [
      {
        id: 1,
        quality: `Глубина/насыщенность`,
        rating: 4,
      },
      {
        id: 2,
        quality: `Чистота вкуса`,
        rating: 4,
      },
      {
        id: 3,
        quality: `Аромат`,
        rating: 5,
      },
      {
        id: 4,
        quality: `Гладкость/текстура вкуса`,
        rating: 4,
      },
      {
        id: 5,
        quality: `Общая оценка`,
        rating: 4.1,
      }
    ],
    "isRound": false,
  },
  {
    "id": 72,
    "name": `Хайвань 2021 (1кг)`,
    "posterImage": `img/haiwan1kg.jpg`,
    "previewImage": `img/haiwan1kg_1.jpg`,
    "backgroundImage": `img/the-grand-budapest-hotel-bg.jpg`,
    "background_color": `#ffffff`,
    "video_link": `https://some-link`,
    "preview_video_link": `https://some-link`,
    "description": `Чай интересен своими травяными нотками - эстрагон, луговые травы. Кондитерская вкусовая составляющая делает чай более понятным для тех, кто только знакомится с миром шу-пуэров.`,
    "rating": 8.9,
    "scoresCount": 240,
    "director": `Wes Andreson`,
    "starring": [`Bill Murray`, `Edward Norton`, `Jude Law`, `Willem Dafoe`, `Saoirse Ronan`],
    "run_time": 99,
    "genre": `Хайвань`,
    "released": 2021,
    "is_black": false,
    "cost": 4115,
    "weight": 1000,
    "amount": 990,
    "profile": [
      {
        id: 1,
        tone: `травяной`,
        markers: `эстрагон`
      },
      {
        id: 2,
        tone: `древесный`,
        markers: ``
      },
      {
        id: 3,
        tone: `кондитерский`,
        markers: `мёд, брауни`
      }
    ],
    "profileQuality": [
      {
        id: 1,
        quality: `Глубина/насыщенность`,
        rating: 4,
      },
      {
        id: 2,
        quality: `Чистота вкуса`,
        rating: 3.5,
      },
      {
        id: 3,
        quality: `Аромат`,
        rating: 4.5,
      },
      {
        id: 4,
        quality: `Гладкость/текстура вкуса`,
        rating: 4,
      },
      {
        id: 5,
        quality: `Общая оценка`,
        rating: 4,
      }
    ],
    "isRound": false,
  },
  {
    "id": 73,
    "name": `Лао Шу 1991`,
    "posterImage": `img/laoshu.jpg`,
    "previewImage": `img/laoshu_1.jpg`,
    "backgroundImage": `img/the-grand-budapest-hotel-bg.jpg`,
    "background_color": `#ffffff`,
    "video_link": `https://some-link`,
    "preview_video_link": `https://some-link`,
    "description": ``,
    "rating": 8.9,
    "scoresCount": 240,
    "director": `Wes Andreson`,
    "starring": [`Bill Murray`, `Edward Norton`, `Jude Law`, `Willem Dafoe`, `Saoirse Ronan`],
    "run_time": 99,
    "genre": `Лао Шу`,
    "released": 1991,
    "is_black": false,
    "cost": 3260,
    "weight": 300,
    "amount": 285,
    "profile": [
      {
        id: 1,
        tone: `древесный`,
        markers: `лес, кора дуба, ветки`
      },
      {
        id: 2,
        tone: `овощной`,
        markers: `овощной склад`
      },
      {
        id: 3,
        tone: `смолистый`,
        markers: `смола`
      },
      {
        id: 4,
        tone: `камфорный`,
        markers: `дикая камфора`
      },
      {
        id: 5,
        tone: `шоколадный`,
        markers: `тёмный шоколад, какао`
      },
      {
        id: 6,
        tone: `лекарственный`,
        markers: `настой коры дуба`
      }
    ],
    "profileQuality": [
      {
        id: 1,
        quality: `Глубина/насыщенность`,
        rating: 4,
      },
      {
        id: 2,
        quality: `Чистота вкуса`,
        rating: 4,
      },
      {
        id: 3,
        quality: `Аромат`,
        rating: 4,
      },
      {
        id: 4,
        quality: `Гладкость/текстура вкуса`,
        rating: 4,
      },
      {
        id: 5,
        quality: `Общая оценка`,
        rating: 4,
      }
    ],
  },
];
