import React from 'react';
import {BarChart} from '../charts/BarChart/BarChart';
import {ScatterPlot} from '../charts/ScatterPlot/ScatterPlot';
import {ScatterPlotByYear} from '../charts/ScatterPlotByYear/ScatterPlotByYear';

const GraphicsPage = (props) => {
  const {films, onSort, onSortByPrice, onSortByYear} = props;

  return (

    <section className="graphics" style={{width: `100%`}} >
      <h1>Графики</h1>
      <p>Если Вы просматриваете эту страницу с телефона, лучше использовать горизонтальную ориентацию экрана. Если она не включается автоматически, Вы можете изменить это в настройках телефона.</p>
      <ScatterPlot style={{width: `100%`}} films={films} />
      <button type="button" style={{width: `100%`}} onClick={() => onSort()}>
              Сортировать по рейтингу
      </button>
      <button type="button" style={{width: `100%`}} onClick={() => onSortByPrice()}>
              Сортировать по цене
      </button>
      <button type="button" style={{width: `100%`}} onClick={() => onSortByYear()}>
              Сортировать по возрасту
      </button>
      <BarChart films={films} />
      <ScatterPlotByYear films={films} />
    </section>

  );
};

export default GraphicsPage;
