import React from 'react';
import {Link} from "react-router-dom";

export const AxisLeft = ({yScale, films}) => {
  // const {films} = films;
  const {tea} = films;
  // films.map((tea) => tea.id);
  let x = 0;
  let myTea;
  const isGoodId = (tea, tickValue) => {
    if (tea.name === tickValue) {
      return tea.id;
    } return 0;
  };

  return (
    yScale.domain().map((tickValue) =>
    // {x = }
    // myTea = films.filter(function (tea) {
    //   tea.name === tickValue;
    //   return tea.id;
    // })

      <Link to={`films/${films.filter(isGoodId)}`} className="small-movie-card__link" key={tickValue} >
        <text
          key={tickValue}
          style={{textAnchor: `end`}}
          x={-3}
          dy=".32em"
          y={yScale(tickValue) + yScale.bandwidth() / 2}
          fill='brown'
          // textDecoration='underline wavy'
        >
          {tickValue}
        </text>
      </Link>
    ));
};

// tea.name === tickValue ? tea.id : 1
