import React from 'react';

export const Marks = ({films, yScale, xScale}) =>
  films.map((tea) => (
    <rect
      key={tea.name}
      y={yScale(tea.name)}
      width={xScale(tea.cost / tea.weight)}
      // width={500}
      height={yScale.bandwidth()}
      fill='indigo'
      opacity={0.7}
    >
      <title>{(tea.cost / tea.weight).toFixed(2)} ₽</title>
    </rect>
  ));
