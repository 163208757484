import React from 'react';
import {Link} from "react-router-dom";

export const Marks = ({films, yScale, xScale}) =>
  films.map((tea) => (
    <Link to={`teas/${tea.id}`} className="small-movie-card__link" key={tea.name} >
      <circle
        key={tea.name}
        cx={xScale(tea.released)}
        cy={yScale(tea.profileQuality[4].rating)}
        fill='indigo'
        opacity={0.7}
        r={6.5}
      >
        <title className='word-for-marks'>
          {tea.name};
          цена: {(tea.cost / tea.weight).toFixed(2)} ₽;
          оценка: {(tea.profileQuality[4].rating)};
        </title>
      </circle></Link>
  ));
