import React from 'react';
import {Link} from "react-router-dom";
// import PropTypes from 'prop-types';


const MusicPage = () => {

  return (
    <div className="user-page">
      <header className="page-header user-page__head">
        <div className="logo">
          <Link to="/" className="logo__link">
            <span className="logo__letter logo__letter--1">W</span>
            <span className="logo__letter logo__letter--2">T</span>
            <span className="logo__letter logo__letter--3">D</span>
          </Link>
        </div>

        <h1 className="page-title user-page__title">Музыка для чая</h1>

        <div className="user-block">
          <div className="user-block__avatar">
            <img src="img/ban006.jpg" alt="User avatar" width="63" height="63" />
          </div>
        </div>
      </header>

      <section className="catalog catalog__music">
        <h2 className="catalog__title visually-hidden">Catalog</h2>

        <div style={{color: `black`}}>


          <figure>
            <figcaption>Протяжённость и спокойствие</figcaption>
            <audio controls loop src="/audio/first.m4a"></audio>
            <a href="/audio/first.m4a"> Download audio </a>
          </figure>

          <figure>
            <figcaption>Звуки и тишина</figcaption>
            <audio controls loop src="/audio/second.m4a"></audio>
            <a href="/audio/second.m4a"> Download audio </a>
          </figure>
        </div>


      </section>

      <footer className="page-footer">
        <div className="logo">
          <Link to="/" className="logo__link logo__link--light">
            <span className="logo__letter logo__letter--1">W</span>
            <span className="logo__letter logo__letter--2">T</span>
            <span className="logo__letter logo__letter--3">D</span>
          </Link>
        </div>

        <div className="copyright">
          <p>© 2024 What to drink Ltd.</p>
        </div>
      </footer>
    </div>
  );
};


export default MusicPage;
