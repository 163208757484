import React from 'react';
import {scaleBand, scaleLinear, max} from 'd3';
import {AxisBottom} from './components/AxisBottom';
import {AxisLeft} from './components/AxisLeft';
import {Marks} from './components/Marks';

const height = 600;
const width = 1300;
// const width = 100vw;
const margin = {top: 50, right: 30, bottom: 40, left: 500};
// const margin = {top: 5vw, right: 30vw, bottom: 40vw, left: 500vw};


export const BarChart = ({films}) => {

  const innerHeight = height - margin.top - margin.bottom;
  const innerWidth = width - margin.left - margin.right;

  const yScale = scaleBand()
    .domain(films.map((tea) => tea.name))
    .range([0, innerHeight])
    .padding(0.4);

  const xScale = scaleLinear()
    .domain([0, max(films, (tea) => tea.cost / tea.weight)])
    .range([0, innerWidth]);

  return (
    <section>
      <svg width={width} height={height} >
        <g transform={`translate(${margin.left},${margin.top})`}>
          <AxisBottom xScale={xScale} innerHeight={innerHeight}/>
          <AxisLeft yScale={yScale} films={films}/>
          <text
            x={innerWidth / 2 - margin.left / 2 - margin.right}
            y={-20}
            fontSize={30}
            fill='brown'
            textDecoration='underline'
            // textAnchor='middle'
          >Цена чая за грамм</text>
          <Marks films={films} xScale={xScale} yScale={yScale}/>


        </g>
      </svg>
    </section>
  );

};
