import React from 'react';
import PropTypes from 'prop-types';
import OrderList from '../order-list/order-list';
import {Film} from '../../prop-types/film';
import {Link} from "react-router-dom";
// import './order-chat-mechanics.js';


const TELEGRAM_BOT_TOKEN = `6384925359:AAH_Em-0Ve73G8WhOlbcnB8_QjU1Dy8Ubp0`;
const TELEGRAM_CHAT_ID = `@HeishuOrder`;
const API = `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`;


async function sendEmailTelegram(event) {
  // const {order} = props;


  event.preventDefault();
  // const {order} = props;


  const form = event.target;


  const {orderorder, orderTele, name, phone} = Object.fromEntries(new FormData(form).entries());
  // const {orderTele} = Object.fromEntries(new FormData(form));

  // const {orderTele} = new FormData(form);
  // const {order} = new FormData(form).toSring();

  // const justOrderText = order;
  // const justOrderText = JSON.stringify(order);

  console.log(orderTele);
  // console.log(order);

  const tryAnotherTime = JSON.stringify(new FormData(form));

  const text = `Пришёл заказ ${orderorder} от ${name} с номером телефона ${phone}, ${orderTele}`;


  try {
    const response = await fetch(API, {
      method: `POST`,
      headers: {
        'Content-Type': `application/json`
      },
      body: JSON.stringify({
        chat_id: TELEGRAM_CHAT_ID,
        text,
      })
    });
  } catch (error) {

  }

}


const OrderPage = (props) => {

  const {order, onDelete} = props;
  // const orderForMe = order.toString();
  // const letMeKnowWhatYouWant = order.
  console.log(order);
  let summa = 0;
  order.forEach((film) => summa += film.price);
  return (
    <div className="user-page">
      <header className="page-header user-page__head">
        <div className="logo">
          <Link to="/" className="logo__link">
            <span className="logo__letter logo__letter--1">W</span>
            <span className="logo__letter logo__letter--2">T</span>
            <span className="logo__letter logo__letter--3">D</span>
          </Link>
        </div>

        <h1 className="page-title user-page__title">Корзина с товарами</h1>

        <div className="user-block">
          <div className="user-block__avatar">
            <img src="img/avatar.jpg" alt="User avatar" width="63" height="63" />
          </div>
        </div>
      </header>

      <section className="catalog">
        <h2 className="catalog__title visually-hidden">Catalog</h2>

        <div className="catalog__movies-list">
          <OrderList films={order} onDelete={onDelete} />

        </div>
        <h2>Итого: {summa} ₽</h2>

        <form className="form" onSubmit={sendEmailTelegram}>
          <input type="hidden" name="orderTele" value={order}/>
          <input name="name" placeholder='Напишите Ваше имя' />
          <input name="phone" type='phone' placeholder='Введите Ваш номер' />
          <input name="orderorder" value={order.map((one) => `Чай + ${one.name} + вес + ${one.count} + общая сумма + ${summa}`)} type="hidden" />
          <button type="submit">Отправить заказ</button>
        </form>

        <h2><a href='https://web.telegram.org/a/#-4007260834'>Для заказа Вы также можете писать на номер 8 952 270 4267, в Телеграм, Whatts App. Проект на стадии становления, условия покупки/доставки обговорим индивидуально, весь представленный чай в наличии. Илья.</a></h2>
        <img src='img\for_orders.jpg' alt="QR for orders" width='300' />
      </section>

      <section>

      </section>

      <footer className="page-footer">
        <div className="logo">
          <Link to="/" className="logo__link logo__link--light">
            <span className="logo__letter logo__letter--1">W</span>
            <span className="logo__letter logo__letter--2">T</span>
            <span className="logo__letter logo__letter--3">D</span>
          </Link>
        </div>

        <div className="copyright">
          <p>© 2024 What to drink Ltd.</p>
        </div>
      </footer>
    </div>
  );
};

OrderPage.propTypes = {
  order: PropTypes.arrayOf(Film).isRequired,
  onDelete: PropTypes.func.isRequired
  // films: PropTypes.array.isRequired,
};

export default OrderPage;
