import React from 'react';
import MainPage from '../main-page/main-page';
import PropTypes from 'prop-types';
import {Switch, Route, BrowserRouter} from 'react-router-dom';
import AddReviewPage from '../add-review-page/add-review-page';
import FilmPage from '../film-page/film-page';
import MyListPage from '../my-list-page/my-list-page';
import NotFoundScreen from '../not-found-screen/not-found-screen';
import SignInPage from '../sign-in-page/sign-in-page';
import OrderPage from '../order-page/order-page';
import GraphicsPage from '../graphics-page/graphics-page';
import MyTextPage from '../text-page/text-page';
import MusicPage from '../music-page/music-page';
import {Film} from '../../prop-types/film';
import {Helmet} from 'react-helmet';
// import {useMemo} from 'react';
// import { films } from '../../mocks/films';

const App = (props) => {
  const {films} = props;
  const [order, setOrder] = React.useState([]);
  const addToOrder = (film) => {

    setOrder([...order, film]);
    alert(`Товар добавлен`);
    // console.log(order);
  };
  // const {film} = films;
  const {id} = films;
  const deleteOrder = (id) => {
    setOrder(order.filter((film) => film.id !== id));
    // console.log(order);
  };
  // sessionStorage.setItem(`currentOrder`, order);
  // console.log(order);
  const [filmsInSort, setFilmsInSort] = React.useState(films);
  const trySort = () => {

    setFilmsInSort(() => [...filmsInSort].sort(function (a, b) {
      return +b.profileQuality[4].rating - a.profileQuality[4].rating;
    }));

  };

  const trySortByPrice = () => {
    setFilmsInSort(() => [...filmsInSort].sort(function (a, b) {
      return +(b.cost / b.weight) - (a.cost / a.weight);
    }));
  };

  const trySortByYear = () => {
    setFilmsInSort(() => [...filmsInSort].sort(function (a, b) {
      return -b.released + a.released;
    }));
  };

  const filterAllTea = () => {
    event.preventDefault();
    setFilmsInSort(films);
  };

  const filterHaivan = () => {
    event.preventDefault();
    setFilmsInSort(() => [...films].filter((tea) => tea.genre === `Хайвань`));
  };

  const filterDaI = () => {
    event.preventDefault();
    setFilmsInSort(() => [...films].filter((tea) => tea.genre === `Да И`));
  };

  const filterLimin = () => {
    event.preventDefault();
    setFilmsInSort(() => [...films].filter((tea) => tea.genre === `Лимин Бацзяотин`));
  };

  const filterTulin = () => {
    event.preventDefault();
    setFilmsInSort(() => [...films].filter((tea) => tea.genre === `Тулинь`));
  };

  const filterJunCha = () => {
    event.preventDefault();
    setFilmsInSort(() => [...films].filter((tea) => tea.genre === `Чжун Ча`));
  };

  const filterUnGen = () => {
    event.preventDefault();
    setFilmsInSort(() => [...films].filter((tea) => tea.genre === `Юн Чжень`));
  };


  const filterBlack = () => {
    event.preventDefault();
    setFilmsInSort(() => [...films].filter((tea) => tea.is_black));
  };

  const filterShu = () => {
    event.preventDefault();
    setFilmsInSort(() => [...films].filter((tea) => !tea.is_black));
  };


  const filterWooden = () => {
    setFilmsInSort(() => [...films].filter((tea) => tea.profile[0].tone === `древесный`));
  };

  const filterResin = () => {
    setFilmsInSort(() => [...films].filter((tea) => tea.profile[0].tone === `смолистый`));
  };

  const filterFruit = () => {
    setFilmsInSort(() => [...films].filter((tea) => tea.profile[0].tone === `сухофруктовый` || tea.profile[0].tone === `фруктовый`));
  };

  const filterNuts = () => {
    setFilmsInSort(() => [...films].filter((tea) => tea.profile[0].tone === `ореховый` || tea.profile[0].tone === `жареный`));
  };

  const filterBerries = () => {
    setFilmsInSort(() => [...films].filter((tea) => tea.profile[0].tone === `ягодный`));
  };

  const filterMusk = () => {
    setFilmsInSort(() => [...films].filter((tea) => tea.profile[0].tone === `мускусный` || tea.profile[0].tone === `животный`));
  };

  const filterChoco = () => {
    setFilmsInSort(() => [...films].filter((tea) => tea.profile[0].tone === `шоколадный`));
  };

  const filterHvoya = () => {
    setFilmsInSort(() => [...films].filter((tea) => tea.profile[0].tone === `хвойный`));
  };

  const filterCamphor = () => {
    setFilmsInSort(() => [...films].filter((tea) => tea.profile[0].tone === `камфорный`));
  };

  // trySort();
  // useEffect(() => {
  //   filmsInSort = {filmsInSort};
  // }, [filmsInSort]);
  // const sortedTea = setFilmsInSort( filmsInSort => [...filmsInSort].sort(function (a, b) {
  //   return a.profileQuality[4].rating < b.profileQuality[4].rating;
  // }));

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <MainPage
            films={filmsInSort}
            onSort={trySort}
            onSortByPrice={trySortByPrice}
            onSortByYear={trySortByYear}
            filterHaivan={filterHaivan}
            filterAllTea={filterAllTea}
            filterDaI={filterDaI}
            filterLimin={filterLimin}
            filterTulin={filterTulin}
            filterJunCha={filterJunCha}
            filterUnGen={filterUnGen}

            filterBlack={filterBlack}
            filterShu={filterShu}

            filterWooden={filterWooden}
            filterResin={filterResin}
            filterFruit={filterFruit}
            filterNuts={filterNuts}
            filterBerries={filterBerries}
            filterMusk={filterMusk}
            filterChoco={filterChoco}
            filterHvoya={filterHvoya}
            filterCamphor={filterCamphor}
          />

        </Route>
        <Route exact path="/teas/:id/review">
          <AddReviewPage film={films[id]} />
        </Route>
        <Route exact path="/teas/:id">

          <FilmPage film={films[id]} onAdd={addToOrder} />

        </Route>
        <Route exact path="/mylist">
          <MyListPage
            films={filmsInSort}
            onSort={trySort}
          />
        </Route>
        <Route exact path="/login">
          <SignInPage />
        </Route>
        <Route exact path="/teabucket">
          <OrderPage order={order} onDelete={deleteOrder}/>
        </Route>
        <Route exact path="/graphics">
          <GraphicsPage
            films={filmsInSort}
            onSort={trySort}
            onSortByPrice={trySortByPrice}
            onSortByYear={trySortByYear}
          />
        </Route>
        <Route exact path="/texts">
          <MyTextPage />
        </Route>
        <Route exact path="/music">
          <MusicPage />
        </Route>
        <Route>
          <NotFoundScreen />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

App.propTypes = {
  films: PropTypes.arrayOf(Film).isRequired,
  // films: PropTypes.arrayOf(Film).isRequired,
};

export default App;
