import React from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
// import { Film } from '../../prop-types/film';

const FilmCard = (props) => {
  const {film} = props;
  const {name, posterImage, isRound} = film;
  // isRound ? borderRadius: `50%` : borderRadius:`100%`;
  return (
    <>
      <Link to={`teas/${film.id}`} className="small-movie-card__link" >
        <div className="small-movie-card__image">

          <img src={posterImage} alt="Fantastic Beasts: The Crimes of Grindelwald" width="280" height="175" style={isRound ? {borderRadius: `50%`} : {borderRadius: `0%`}} />

        </div>
        <h3 className="small-movie-card__title">
          <Link to={`teas/${film.id}`} className="small-movie-card__link" >{name}</Link>
        </h3>
      </Link>
    </>
  );
};


FilmCard.propTypes = {
  film: PropTypes.shape({
    name: PropTypes.string.isRequired,
    posterImage: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    isRound: PropTypes.bool.isRequired,
    // id: PropTypes.number.isRequired,
  })
};


export default FilmCard;


